<template>
    <div class="Awarpblue Awarp ">
        <dashCard class="energykanbanBox9" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">电量使用排行榜</template>
            <template slot="aside"></template>

            <div class="card-content1">
                <div class="aside-item">
                    <div class="aside-item-title">公司电总使用量</div>
                    <div class="aside-item-desc">
                        {{ toThousandsSeparator(detailInfo.value1) }}
                        <font>万kWh</font>
                    </div>
                </div>
            </div>
            <div class="card-content2">
                <div class="list-header">
                    <span>公司名称</span>
                    <span>TOP</span>
                    <span>耗电量(万kWh)</span>
                </div>
                <ul class="list-content">
                    <li class="list-item" v-for="(item,idx) in detailInfo.list" :key="idx">
                        <span>{{ item.name }}</span>
                        <span>{{ item.sort }}</span>
                        <span>{{ item.value }}</span>
                    </li>
                </ul>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <energykanbanBox9  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'energykanbanBox9',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中
                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    chart1: {},
                },
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

 
        // 改变类型
        changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
            this.$forceUpdate()
        },
        // 切换项目
        handleChange(value) {
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {
                value1: 11235.50,
                list: [
                    {
                        name:"公司名称一",
                        sort:1,
                        value: 405,
                    },
                    {
                        name:"公司名称二",
                        sort:2,
                        value: 305,
                    },
                    {
                        name:"公司名称三",
                        sort:3,
                        value: 400,
                    },
                    {
                        name:"公司名称四",
                        sort:4,
                        value: 355,
                    },
                    {
                        name:"公司名称五",
                        sort:5,
                        value: 375,
                    },
                ]
            }
            this.isLoading = false
        },
  
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.energykanbanBox9 {
    .card-content1 {
        display: flex;
        height: 96px;
        .aside-item {
            // width: 170px;
            .aside-item-title {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
                line-height: 22px;
            }
            .aside-item-desc {
                font-size: 36px;
                font-weight: 400;
                color: #ffffff;
                line-height: 44px;
                font {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 20px;
                }

            }
            .aside-item-desc1 {
                margin-top: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
            }
        }
    }
    .card-content2 {
        height: 250px;
        width: 100%;
        .list-header {
            height: 34px;
            background: rgba(255,255,255,.1);
            border-radius: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 11px;
            box-sizing: border-box;
            span {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
                min-width: 60px;
            }
        }
        .list-content {
            height: 200px;
            overflow: auto;
            .list-item {
                height: 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 11px;
                box-sizing: border-box;
                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 17px;
                    min-width: 60px;
                }
            }


        }
    }
                /* 整个滚动条 */
            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }

            /* 滚动条上的滚动滑块 */
            ::-webkit-scrollbar-thumb {
                background-color: #4c7dec;
                /* 关键代码 */
                // background-image: -webkit-linear-gradient(45deg,
                //         rgba(255, 255, 255, 0.4) 25%,
                //         transparent 25%,
                //         transparent 50%,
                //         rgba(255, 255, 255, 0.4) 50%,
                //         rgba(255, 255, 255, 0.4) 75%,
                //         transparent 75%,
                //         transparent);
                border-radius: 32px;
            }

            /* 滚动条轨道 */
            ::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 32px;
            }
}


</style>